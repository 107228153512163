<template>
  <v-card elevation="0" width="100%">
    <v-row>
      <v-col cols="12" :class="$vuetify.breakpoint.mdAndUp ? '' : 'pb-0'">
        <v-btn
          class="pl-0"
          v-if="$vuetify.breakpoint.xsOnly"
          text
          small
          style="vertical-align: top"
          @click="$emit('backtoUserSetting')"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span
          class="grey--text text--darken-4 text-h6 font-weight-medium"
          :class="$vuetify.breakpoint.mdAndUp ? 'px-4' : 'pr-4'"
        >
          {{ "Notification Preferences" }}
        </span>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndUp">
      <v-col cols="12">
        <v-card-text class="pa-0">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    width="63%"
                    class="grey--text text--darken-2 caption font-weight-medium"
                  >
                    {{ $t("notifications") }}
                  </th>
                  <th
                    width="13%"
                    class="grey--text text--darken-2 font-weight-medium caption"
                  >
                    {{ $t("inApplication") }}
                  </th>
                  <th
                    width="12%"
                    class="grey--text text--darken-2 font-weight-medium caption"
                  >
                    {{ $t("email") }}
                  </th>
                  <th
                    width="12%"
                    class="grey--text text--darken-2 font-weight-medium caption"
                  >
                    {{ $t("smsText") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="notificationName in notificationList"
                  :key="notificationName.text"
                >
                  <td>
                    <span
                      class="mx-auto grey--text text--darken-4 font-weight-regular"
                    >
                    {{ notificationDetails(notificationName.text) }}
                  </span>
                  </td>
                  <td>
                    <v-checkbox
                      v-model="notificationName.inApplication"
                      label=""
                      :value="!notificationName.inApplication"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox
                      v-model="notificationName.email"
                      label=""
                      :value="!notificationName.email"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox
                      v-model="notificationName.sms"
                      label=""
                      :value="!notificationName.sms"
                    ></v-checkbox>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        cols="12"
        class="px-3 pt-0 pb-1"
        v-for="notificationName in notificationList"
        :key="notificationName.text"
      >
        <v-row no-gutters class="ml-2 mt-2"
          ><span
            class="grey--text text--darken-4 font-weight-regular text-body-2"
          >
          {{ notificationDetails(notificationName.text) }}
          </span
        ></v-row>
        <v-row
          no-gutters
          align="center"
          class="ml-4 grey--text text--darken-4 font-weight-regular text-body-2"
          ><v-checkbox
            class="my-1"
            v-model="notificationName.inApplication"
            label=""
            hide-details
            :value="!notificationName.inApplication"
          ></v-checkbox
          >{{ $t("inApplication") }}</v-row
        >
        <v-row
          no-gutters
          align="center"
          class="ml-4 grey--text text--darken-4 font-weight-regular text-body-2"
          ><v-checkbox
            class="my-1"
            v-model="notificationName.email"
            label=""
            hide-details
            :value="!notificationName.email"
          ></v-checkbox
          >{{ $t("email") }}</v-row
        >
        <v-row
          no-gutters
          align="center"
          class="ml-4 grey--text text--darken-4 font-weight-regular text-body-2"
          ><v-checkbox
            class="my-1"
            v-model="notificationName.sms"
            label=""
            hide-details
            :value="!notificationName.sms"
          ></v-checkbox
          >{{ $t("smsText") }}</v-row
        >
        <v-col cols="12" class="py-2 px-0">
          <v-divider></v-divider>
        </v-col>
      </v-col>
    </v-row>
    <v-row class="mb-16 mt-4">
      <v-col
        v-if="$vuetify.breakpoint.smAndUp"
        cols="12"
        lg="2"
        md="2"
        sm="2"
        xl="2"
        class="ml-8 pa-0 mt-4"
      >
        <v-btn
          block
          color="primary"
          class="text-body-2 text-none font-weight-regular rounded-lg"
          :disabled="!isDifferent"
          @click="submit"
        >
          {{ $t("update") }}
        </v-btn>
      </v-col>
      <v-bottom-navigation
        v-else
        fixed
        horizontal
        height="70"
        class="px-2 py-4"
      >
        <v-row class="px-4 py-2">
          <v-btn
            color="primary"
            block
            large
            class="text-body-1 text-none font-weight-regular rounded-lg"
            :disabled="!isDifferent"
            @click="submit"
          >
            {{ $t("update") }}
          </v-btn>
        </v-row>
      </v-bottom-navigation>
    </v-row>
  </v-card>
</template>

<script>
const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);
const cloneDeep = (a) => JSON.parse(JSON.stringify(a));
export default {
  props: {},
  created() {
    this.notificationList = this.notification;
    if (this.notification) this.setNotificationList(this.notification);
  },
  data() {
    return {
      inAppPreferencesList: [],
      emailPreferencesList: [],
      smsPreferencesList: [],
      notificationList: [],
      updatedNotificationList: [],
      isDifferent: false,
    };
  },
  computed: {
    notification() {
      return this.$store.state.User?.user?.options?.preferences?.notification;
    },
  },
  watch: {
    notificationList: {
      handler() {
        this.isDifferent = !isEqual(
          this.notificationList,
          this.updatedNotificationList
        );
      },
      deep: true,
    },
    notification() {
      this.notificationList = this.notification;
      if (this.notification) this.setNotificationList(this.notification);
    },
  },
  methods: {
    notificationDetails(text) {
      if (text == "Ticket submission 811 center confirmation")
        return "Ticket submission 811 center confirmation (Center Excavator Confirmation)";
      else if (text == "Ticket ready to Dig based on responses received")
        return "Ticket ready to Dig based on responses received (Sent on response due by date)";
      else if (text == "Ticket not clear to dig")
        return "Ticket not clear to dig (All responses from utility not received but ticket is overdue)";
      else if (text == "Ticket revisions updates")
        return "Ticket revisions updates (from Newtin)";
      return text;
    },
    async submit() {
      const updateNotificationPreferences = await this.$store.dispatch(
        "updateUser",
        {
          id: this.$store.state.User.user.user_id,
          options: {
            preferences: {
              notification: this.notificationList,
            },
          },
        }
      );
      if (updateNotificationPreferences.status !== "error") {
        this.$store.commit("setUserData", {
          options: {
            preferences: {
              notification: this.notificationList,
            },
          },
        });
        this.$emit("updateNotificationPreferences");
        this.setNotificationList(this.notificationList);
        this.$emit("backtoUserSetting");
      } else {
        this.$emit("error");
      }
    },
    setNotificationList(notificationList) {
      this.updatedNotificationList = cloneDeep(notificationList);
      this.isDifferent = false;
    },
  },
};
</script>
<style scoped>
.theme--light.v-bottom-navigation .v-btn:not(.v-btn--active) {
  color: #f5f5f5 !important;
}
</style>

import { render, staticRenderFns } from "./NotificationPreferences.vue?vue&type=template&id=58d9055c&scoped=true&"
import script from "./NotificationPreferences.vue?vue&type=script&lang=js&"
export * from "./NotificationPreferences.vue?vue&type=script&lang=js&"
import style0 from "./NotificationPreferences.vue?vue&type=style&index=0&id=58d9055c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58d9055c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBottomNavigation,VBtn,VCard,VCardText,VCheckbox,VCol,VDivider,VIcon,VRow,VSimpleTable})


/* hot reload */
if (module.hot) {
  var api = require("N:\\diglogix\\diglogix-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('58d9055c')) {
      api.createRecord('58d9055c', component.options)
    } else {
      api.reload('58d9055c', component.options)
    }
    module.hot.accept("./NotificationPreferences.vue?vue&type=template&id=58d9055c&scoped=true&", function () {
      api.rerender('58d9055c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/workflow/userSettings/NotificationPreferences.vue"
export default component.exports
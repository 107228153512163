var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { elevation: "0", width: "100%" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.mdAndUp ? "" : "pb-0",
              attrs: { cols: "12" },
            },
            [
              _vm.$vuetify.breakpoint.xsOnly
                ? _c(
                    "v-btn",
                    {
                      staticClass: "pl-0",
                      staticStyle: { "vertical-align": "top" },
                      attrs: { text: "", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("backtoUserSetting")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                    1
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass:
                    "grey--text text--darken-4 text-h6 font-weight-medium",
                  class: _vm.$vuetify.breakpoint.mdAndUp ? "px-4" : "pr-4",
                },
                [_vm._v(" " + _vm._s("Notification Preferences") + " ")]
              ),
            ],
            1
          ),
          _vm.$vuetify.breakpoint.xsOnly
            ? _c("v-col", { attrs: { cols: "12" } }, [_c("v-divider")], 1)
            : _vm._e(),
        ],
        1
      ),
      _vm.$vuetify.breakpoint.smAndUp
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pa-0" },
                    [
                      _c("v-simple-table", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c("thead", [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "grey--text text--darken-2 caption font-weight-medium",
                                          attrs: { width: "63%" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("notifications")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "grey--text text--darken-2 font-weight-medium caption",
                                          attrs: { width: "13%" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("inApplication")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "grey--text text--darken-2 font-weight-medium caption",
                                          attrs: { width: "12%" },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("email")) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "grey--text text--darken-2 font-weight-medium caption",
                                          attrs: { width: "12%" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("smsText")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.notificationList,
                                      function (notificationName) {
                                        return _c(
                                          "tr",
                                          { key: notificationName.text },
                                          [
                                            _c("td", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "mx-auto grey--text text--darken-4 font-weight-regular",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.notificationDetails(
                                                          notificationName.text
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    label: "",
                                                    value:
                                                      !notificationName.inApplication,
                                                  },
                                                  model: {
                                                    value:
                                                      notificationName.inApplication,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        notificationName,
                                                        "inApplication",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "notificationName.inApplication",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    label: "",
                                                    value:
                                                      !notificationName.email,
                                                  },
                                                  model: {
                                                    value:
                                                      notificationName.email,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        notificationName,
                                                        "email",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "notificationName.email",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    label: "",
                                                    value:
                                                      !notificationName.sms,
                                                  },
                                                  model: {
                                                    value: notificationName.sms,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        notificationName,
                                                        "sms",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "notificationName.sms",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1598955283
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-row",
            _vm._l(_vm.notificationList, function (notificationName) {
              return _c(
                "v-col",
                {
                  key: notificationName.text,
                  staticClass: "px-3 pt-0 pb-1",
                  attrs: { cols: "12" },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "ml-2 mt-2", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "grey--text text--darken-4 font-weight-regular text-body-2",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.notificationDetails(notificationName.text)
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "ml-4 grey--text text--darken-4 font-weight-regular text-body-2",
                      attrs: { "no-gutters": "", align: "center" },
                    },
                    [
                      _c("v-checkbox", {
                        staticClass: "my-1",
                        attrs: {
                          label: "",
                          "hide-details": "",
                          value: !notificationName.inApplication,
                        },
                        model: {
                          value: notificationName.inApplication,
                          callback: function ($$v) {
                            _vm.$set(notificationName, "inApplication", $$v)
                          },
                          expression: "notificationName.inApplication",
                        },
                      }),
                      _vm._v(_vm._s(_vm.$t("inApplication"))),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "ml-4 grey--text text--darken-4 font-weight-regular text-body-2",
                      attrs: { "no-gutters": "", align: "center" },
                    },
                    [
                      _c("v-checkbox", {
                        staticClass: "my-1",
                        attrs: {
                          label: "",
                          "hide-details": "",
                          value: !notificationName.email,
                        },
                        model: {
                          value: notificationName.email,
                          callback: function ($$v) {
                            _vm.$set(notificationName, "email", $$v)
                          },
                          expression: "notificationName.email",
                        },
                      }),
                      _vm._v(_vm._s(_vm.$t("email"))),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "ml-4 grey--text text--darken-4 font-weight-regular text-body-2",
                      attrs: { "no-gutters": "", align: "center" },
                    },
                    [
                      _c("v-checkbox", {
                        staticClass: "my-1",
                        attrs: {
                          label: "",
                          "hide-details": "",
                          value: !notificationName.sms,
                        },
                        model: {
                          value: notificationName.sms,
                          callback: function ($$v) {
                            _vm.$set(notificationName, "sms", $$v)
                          },
                          expression: "notificationName.sms",
                        },
                      }),
                      _vm._v(_vm._s(_vm.$t("smsText"))),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-2 px-0", attrs: { cols: "12" } },
                    [_c("v-divider")],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
      _c(
        "v-row",
        { staticClass: "mb-16 mt-4" },
        [
          _vm.$vuetify.breakpoint.smAndUp
            ? _c(
                "v-col",
                {
                  staticClass: "ml-8 pa-0 mt-4",
                  attrs: { cols: "12", lg: "2", md: "2", sm: "2", xl: "2" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "text-body-2 text-none font-weight-regular rounded-lg",
                      attrs: {
                        block: "",
                        color: "primary",
                        disabled: !_vm.isDifferent,
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")]
                  ),
                ],
                1
              )
            : _c(
                "v-bottom-navigation",
                {
                  staticClass: "px-2 py-4",
                  attrs: { fixed: "", horizontal: "", height: "70" },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "px-4 py-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "text-body-1 text-none font-weight-regular rounded-lg",
                          attrs: {
                            color: "primary",
                            block: "",
                            large: "",
                            disabled: !_vm.isDifferent,
                          },
                          on: { click: _vm.submit },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }